import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import { Key } from '@mui/icons-material';
import { logout } from '../../action/auth';
import Divider from '@mui/material/Divider';
import { alpha } from '@mui/material/styles';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { MenuTwoTone } from "@mui/icons-material";
import ButtonBase from '@mui/material/ButtonBase';
import React, { useState,useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useDispatch, useSelector } from 'react-redux';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { SidebarContext } from './contexts/SidebarContext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Grid, IconButton, Menu, Tooltip } from "@mui/material";
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';


const NavBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);

const [anchorEl, setAnchorEl] = useState(null);
const {user} = useSelector((state) => state.user);
const {authData} = useSelector((state) => state.auth);
const userInfo  = authData? authData?.data : user


const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
};

  function UserMenu({ handleClose, logout }) {
    return (
      <MenuList
        sx={{
          p: 1,
          '& .MuiMenuItem-root': {
            borderRadius: 2,
          },
        }}
      >
        <Stack>
          <Typography variant="subtitle2" textAlign="left">
            Settings
          </Typography>
        </Stack>
        <Divider
          sx={{
            borderColor: 'primary.light',
            my: 1,
          }}
        />
        <MenuItem onClick={handleClose} to="/user/page/profile?section=account" component={RouterLink}>
          <ListItemIcon>
            <SettingsOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Account
        </MenuItem>
        <MenuItem onClick={handleClose} to="/user/page/profile?section=profile" component={RouterLink}>
          <ListItemIcon>
            <Person2OutlinedIcon fontSize="small" />
          </ListItemIcon>
          Profile
        </MenuItem>
        <MenuItem onClick={handleClose} to="/user/page/profile?section=security" component={RouterLink}>
          <ListItemIcon>
            <Key fontSize="small" />
          </ListItemIcon>
          Change Password
        </MenuItem>
        <Divider
          sx={{
            borderColor: 'primary.light',
            my: 1,
          }}
        />
        <MenuItem onClick = {() => {logout()}}>
          <ListItemIcon>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </MenuList>
    );
  }
	const handleClose = () => {
		setAnchorEl(null);
	};

  const handleLogout = () => {
		dispatch(logout(navigate));
	};

  return (
      <Grid container sx={{ bgcolor:'customPrimary.main', py:1 }}>
        <Grid item xs={6} md={3}> 
          <Box component="span" sx={{ ml: 2, display:'inline-block'}}>
            {!sidebarToggle ? (
              <Tooltip arrow title="Open Menu">
                  <IconButton  sx={{color :'white'}} onClick={toggleSidebar}>
                      <MenuTwoTone />
                  </IconButton>
              </Tooltip>
              ) : (
                null
              )}
          </Box>
        </Grid>
        <Grid item xs={6} md={9} sx={{justifyContent:"flex-end", display:'flex',pr:2}}>
            <Menu
                elevation={24}
                sx={{ '& .MuiMenuItem-root': { mt: 0.5}}}
                anchorEl={anchorEl}
                disableScrollLock={true}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{ vertical: 'top', horizontal: 'right'}}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <UserMenu handleClose={handleClose} logout={handleLogout}/>
            </Menu>
            <Stack height="100%" direction="row" flex={1} justifyContent="flex-end" alignItems="center" spacing={0}>
              <ButtonBase
                onClick={handleClick}
                variant="outlined"
                sx={{
                  ml: 1,
                  height: '100%',
                  overflow: 'hidden',
                  borderRadius: '25px',
                  transition: '.2s',
                  px: 1,
                  transitionProperty: 'background,color',
                  '&:hover': {
                    bgcolor: (theme) => alpha(theme.palette.primary.main, 0.4),
                  }
                }}
              >

                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                      <Avatar
                        alt="User Img"
                        src={userInfo?.pic}
                        sx={{
                          width: 25,
                          height: 25,
                          boxShadow: (theme) =>
                            `0px 0px 0px 4px ${theme.palette.background.paper} ,0px 0px 0px 5px ${theme.palette.primary.main} `,
                        }}
                      />
                      <Typography
                        sx={{color:'white'}}
                        display={{
                          xs: 'none',
                          sm: 'inline-block',
                        }}
                      >
                        {userInfo?.username}
                      </Typography>
                      <ExpandMoreIcon fontSize="small" sx={{color :'white'}}/>
                    </Stack>
              </ButtonBase>
            </Stack>
        </Grid>
      </Grid>
  );
};
export default NavBar;