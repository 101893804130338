import { START_LOADING, END_LOADING, RESULT, FETCH,CALCULATE_MARK, FETCH_BY_ID, DELETE, UPDATE, CREATE, FETCH_REPORT} from '../constants/actionTypes';

const initialState = {
    status:null,
    results:[],
    submittedResult:[],
    isLoading: false
};

const resultReducer = (state = initialState,action) =>{
    if(action?.payload?.type === RESULT ){
        switch (action.type){
            case START_LOADING:
                return {...state, isLoading:true}
            case END_LOADING:
                return {...state, isLoading:false}
            case RESULT:
                return {...state, submittedResult: action.payload.data};
            case CALCULATE_MARK:
                return {...state, results: action.payload.data};
            case FETCH:
                return {...state, results: action.payload.data};
            case FETCH_REPORT:
                return {...state, status: action.payload.data};  
            case FETCH_BY_ID:
                return {...state, results: action.payload.data};         
            case CREATE:
                return { ...state, results: [...state.results, action.payload.data] };
            case UPDATE:
                return {...state, results: state.results.result.map((result)=> (result._id === action.payload.data._id?action.payload.data:result))};
            case DELETE:
                return {...state, results: {result:state.results.result.filter((result) => result._id !== action.payload.data.result._id)}}
            default:
                return state;
            }
        }
        else{
            return state;
        }}
export default resultReducer;