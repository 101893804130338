import { START_LOADING, END_LOADING, SUBSCRIPTION, FETCH_ALL, FETCH, CREATE, UPDATE, DELETE, FETCH_BY_ID} from '../constants/actionTypes';


const initialState = {
    subscription:[],
    deleteSubscription:[],
    isLoading: false,
};

const subscriptionReducer = (state = initialState,action) =>{
    if(action?.payload?.type === SUBSCRIPTION ){
    switch (action.type){
        case START_LOADING:
            return {...state, isLoading:true}
        case END_LOADING:
            return {...state, isLoading:false}
        case FETCH:
        case FETCH_BY_ID:
        case FETCH_ALL:
            return { ...state, subscription: action.payload.data };    
        case CREATE:
            return { ...state, subscription: [...state.subscription, action.payload.data] };
        case UPDATE:
            return {...state, subscription: action.payload.data};
        case DELETE:
            return {...state, deleteSubscription: state.subscription.map((subscription) => subscription._id !== action.payload) };
        default:
            return state;

    }
}
else{
    return state;
}}

export default subscriptionReducer;