import React from "react";
import {thunk} from 'redux-thunk';
import { StrictMode } from 'react';
import { reducers } from "./reducer";
import { Provider } from 'react-redux'
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { SidebarProvider } from "./pages/global/contexts/SidebarContext";
import { applyMiddleware, legacy_createStore as createStore,compose  } from "redux";

const store = createStore(reducers, {}, compose(applyMiddleware(thunk)))
//StrictMode should be removed for the prod

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <StrictMode>
    <Provider store={store}>
      <SidebarProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </SidebarProvider>
    </Provider>
  // </StrictMode>
);
