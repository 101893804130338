export const RESULT = 'result';
export const CALCULATE='calculate'
export const QUESTION = 'question';
export const CATEGORY = 'category';
export const SUBSCRIPTION = 'subscription';
export const CALCULATE_AND_SAVE='calculate_and_save'
export const EXAM = 'exam';
export const USER = 'user';
export const REPORT = 'report';
export const REPLY_REPORT = 'report/reply';

export const ADD = 'ADD';
export const EDIT = 'EDIT';
export const REMOVE = 'REMOVE';
export const SHOW = 'SHOW';

export const SET_ERROR = 'SET_ERROR'
export const GET_ERROR = 'GET_ERROR'
export const CLEAR_ERROR = 'CLEAR_ERROR'
export const CLEAR_STATE = 'CLEAR_STATE'
export const CLEAR = 'CLEAR'
export const DISABLE = 'DISABLE';
export const REJECT = 'REJECT';
export const EXPIRE = 'EXPIRE';
export const APPROVE = 'APPROVE';
export const SET_SUCCESS = 'SET_SUCCESS'
export const GET_SUCCESS = 'GET_SUCCESS'
export const CLEAR_SUCCESS = 'CLEAR_SUCCESS'
export const FREE = 'free';
export const PAID = 'paid';
export const FETCH  = 'FETCH';
export const FETCH_WITH_QUERY  = 'FETCH_WITH_QUERY';
export const FETCH_REPORT  = 'FETCH_REPORT';
export const FETCH_FREE  = 'FETCH_FREE';
export const FETCH_PAID  = 'FETCH_PAID';
export const FETCH_FULL_INFO = 'FETCH_FULL_INFO';
export const CREATE_FREE = 'CREATE_FREE';
export const UPDATE_FREE = 'UPDATE_FREE';
export const ACTION_FREE = 'ACTION_FREE';
export const DELETE_FREE = 'DELETE_FREE';
export const DELETE_MULTIPLE_FREE = 'DELETE_MULTIPLE_FREE';
export const FETCH_FULL_INFO_BY_ID = 'FETCH_FULL_INFO_BY_ID';
export const FETCH_BY_ID  = 'FETCH_BY_ID';
export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const ACTION = 'ACTION';
export const DELETE = 'DELETE';
export const DELETE_MULTIPLE = 'DELETE_MULTIPLE';
export const FETCH_ALL = 'FETCH_ALL';
export const DELETE_ALL = 'DELETE_ALL';
export const CALCULATE_MARK = 'CALCULATE_MARK';
export const PROFILE_PIC_UPDATE = 'PROFILE_PIC_UPDATE';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const SEND_MAIL = 'SEND_MAIL';

export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';

export const END_LOADING = 'END_LOADING';
export const START_LOADING = 'START_LOADING';

export const SET_TIMEOUT_FLAG = 'SET_TIMEOUT_FLAG';

export const AI = 'ai'