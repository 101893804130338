import { useContext } from 'react';
import SidebarMenu from './SidebarMenu';
import { CloseTwoTone } from '@mui/icons-material';
import logo from './../../../../assets/ethioliq-3.png';
import Scrollbar from '../../../../pages/global/Scrollbar';
import { SidebarContext } from '../../contexts/SidebarContext';
import { Box,Drawer, styled, Divider, useTheme,Tooltip, IconButton } from '@mui/material';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
      width: ${theme.sidebar.width};
      min-width: ${theme.sidebar.width};
      color: ${theme.colors.alpha.trueWhite[100]};
      position: relative;
      z-index: 7;
      height: 100%;
      padding-bottom: 68px;`
  );

function Sidebar() {
  const theme = useTheme();
  const closeSidebar = () => toggleSidebar();
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);

  return (
    <>
    <Drawer variant="persistent" anchor="left" open={sidebarToggle} onClose={closeSidebar}
        sx={{ width: `${theme.sidebar.width}`, flexShrink: 0, display: { xs: 'inline-block', lg: 'inline-block'},
          '& .MuiDrawer-paper': { width: `${theme.sidebar.width}`,  backgroundColor:'customPrimary.main' , boxSizing: 'border-box', overflow: 'auto', }  }}>
      <SidebarWrapper sx={{ position: 'fixed', left: 0,top: 0,  }} >
        <Box component="span" sx={{ mt: 1, mr: 1, display:'flex', alignItems:"center", justifyContent:'space-between'}} >
            <Box
								component="img"
								src={logo}
								alt="ethioliq logo"
								sx={{
									mx: 'auto',
									width: { xs: '80%', sm: '70%' },
									objectFit: 'contain',
									borderBottom: 1,
									borderColor: 'secondary.main',
								}}
							/>
            {sidebarToggle ? (
              <Tooltip arrow title="Close Menu">
                <IconButton sx={{ color:'white' }} onClick={toggleSidebar}>
                  <CloseTwoTone fontSize="small" />
                </IconButton>
              </Tooltip>
              ) : (null)
            }
        </Box>
        <Scrollbar>
          <Divider sx={{ mx: theme.spacing(2), background: theme.colors.alpha.trueWhite[10]}} />
          <SidebarMenu />
        </Scrollbar>
        <Divider sx={{  background: theme.colors.alpha.trueWhite[10]}}/>
      </SidebarWrapper>
    </Drawer>
    </>
  );
}
export default Sidebar;