import { useContext } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from '../../../contexts/SidebarContext';
import { ListSubheader, Box, List, styled, Button, ListItem } from '@mui/material';
import { Badge, Category, Dashboard, House, LocalAtm, Loyalty, ManageAccounts, People, Quiz, RecentActors, Redeem } from '@mui/icons-material';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }
    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {
      .MuiListItem-root {
        padding: 1px 0;    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};
          .MuiButton-startIcon {
            font-size: 20px;
          }
          &.active{
            background-color: #efefef !important;
            color: #07233f;
            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: #07233f;
            }
          }
          &:hover {
            background-color: #718096;
            color: #07233f;
            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: #07233f;
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);
  return (
    <>
      <MenuWrapper>
        <List component="div">
          <SubMenuWrapper>
            <List  component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/"
                  startIcon={<House />}
                >
                  Home
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Dashboard & Main
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  // onClick={closeSidebar}
                  to="main"
                  startIcon={<Dashboard />}
                >
                  Dashboard
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  // onClick={closeSidebar}
                  to="/"
                  startIcon={<RecentActors />}
                >
                  User Page
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Manage Exam
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  // onClick={closeSidebar}
                  to="paid-exam"
                  startIcon={<LocalAtm />}
                >
                  Paid Exam
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  // onClick={closeSidebar}
                  to="free-exam"
                  startIcon={<Redeem />}
                >
                 Free Exam
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  // onClick={closeSidebar}
                  to="question"
                  startIcon={<Quiz />}
                >
                  Question
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  // onClick={closeSidebar}
                  to="category"
                  startIcon={<Category />}
                >
                  Category
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Subscription
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  // onClick={closeSidebar}
                  to="subscription"
                  startIcon={<Loyalty />}
                >
                  Subscription
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  // onClick={closeSidebar}
                  to="subscribed/user"
                  startIcon={<Badge />}
                >
                  Subscribed users
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Report
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  // onClick={closeSidebar}
                  to="report"
                  startIcon={<Loyalty />}
                >
                  Reported Questions
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              User
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  // onClick={closeSidebar}
                  to="user"
                  startIcon={<People />}
                >
                  User
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  // onClick={closeSidebar}
                  to="manage/profile"
                  startIcon={<ManageAccounts />}
                >
                  My Profile
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
      </MenuWrapper>
    </>
  );
}
export default SidebarMenu;