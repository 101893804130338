import { START_LOADING, END_LOADING, FETCH_ALL, FETCH, CREATE, UPDATE, DELETE, CATEGORY, FETCH_BY_ID} from '../constants/actionTypes';

const initialState = {
    category:[],
    isLoading: false,
    selectedCategory:[],
};

const categoryReducer = (state = initialState, action) => {
    if(action?.payload?.type === CATEGORY ){
        switch (action.type) {
          case START_LOADING:
            return { ...state, isLoading: true };
          case END_LOADING:
            return { ...state, isLoading: false };
          case FETCH:
            return { ...state, category: action.payload.data };
          case FETCH_BY_ID:
            return { ...state, selectedCategory: action.payload.data };
          case FETCH_ALL:
            return { ...state, category: action.payload.data };
          case CREATE:
              return {...state, category: [...state.category, action.payload.data] };
          case UPDATE:
              return {...state, category: state.category.map((category) => category._id === action.payload.data._id? {...category, ...action.payload.data}: category)};
          case DELETE:
              return { ...state, category: state.category.filter(
                  (category) => Array.isArray(action.payload.data._id) // If multiple IDs
                      ? !action.payload.data._id.includes(category._id)
                      : category._id !== action.payload.data._id // If single ID
                  ),};
          default:
            return state;
        }
    }
    else{
        return state;
    }
  };
export default categoryReducer;