import { START_LOADING,SET_TIMEOUT_FLAG ,EXAM, END_LOADING, FETCH_ALL, CREATE, UPDATE, DELETE, FETCH, GET_ERROR, CLEAR_ERROR, SET_ERROR, FETCH_BY_ID, FETCH_FREE, CLEAR_STATE, CREATE_FREE, UPDATE_FREE, DELETE_FREE} from '../constants/actionTypes';

const initialState = {
    exam:[],
    exams:[],
    freeExams:[],
    isLoading: false,
    isTimeout: false,
    isTimeReset: false,
    error: null
};

const examReducer = (state = initialState,action) =>{
    if(action?.payload?.type === EXAM ){
        switch (action.type){
            case GET_ERROR:
                return {
                ...state,
                error: null
                };
            case CLEAR_ERROR:
                return {
                ...state,
                error: null
                };
            case SET_ERROR:
                return {
                ...state,
                error: action.payload.error
                };
            case CLEAR_STATE:
                return {
                ...state,
                exam:[],
                exams:[],
                freeExams:[],
                error: null
                };
            case START_LOADING:
                return {...state, isLoading:true}
            case END_LOADING:
                return {...state, isLoading:false}
            case FETCH:
                return {...state, exams: action.payload.data};
            case FETCH_BY_ID:
                return {...state, exam: action.payload.data};
            case FETCH_ALL:
                return {...state, exams: action.payload.data};           
            case CREATE:
                return {...state, exams: [...state.exams, action.payload.data] };
            case UPDATE:
                return {...state, exams: state.exams.map((exam) => exam._id === action.payload.data._id? {...exam, ...action.payload.data}: exam)};
            case DELETE:
                return { ...state, exams: state.exams.filter(
                    (exam) => Array.isArray(action.payload.data._id) // If multiple IDs
                        ? !action.payload.data._id.includes(exam._id)
                        : exam._id !== action.payload.data._id // If single ID
                    ),};
            case DELETE_FREE:
                return { ...state, freeExams: state.freeExams.filter(
                    (exam) => Array.isArray(action.payload.data._id) // If multiple IDs
                        ? !action.payload.data._id.includes(exam._id)
                        : exam._id !== action.payload.data._id // If single ID
                    ),};
            case FETCH_FREE:
                return {...state, freeExams: action.payload.data};
            case CREATE_FREE:
                return { ...state,freeExams: [...state.freeExams, action.payload.data] };
            case UPDATE_FREE:
                return {...state, freeExams: state.freeExams.map((exam) => exam._id === action.payload.data._id? {...exam, ...action.payload.data}: exam)};
            case SET_TIMEOUT_FLAG:
                return { ...state, isTimeout: action.payload.data };
            case "RESET_TIME":
                return { ...state, isTimeReset: action.payload };
            default:
                return state;
        }
    }
    else{
        return state;
    }}
export default examReducer;