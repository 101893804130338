import { IdProvider } from '../../idContext';
import CssBaseline from '@mui/material/CssBaseline';
import { PureLightTheme } from '../../theme/schemes/PureLightTheme';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles';


function MUITheme({ children }) {
	return (
		<StyledEngineProvider injectFirst>
			<MuiThemeProvider theme={PureLightTheme}>
				<IdProvider>
					<CssBaseline />
					{children}
				</IdProvider>
			</MuiThemeProvider>
		</StyledEngineProvider>
	);
}

export default MUITheme;
