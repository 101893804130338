import {AUTH,LOGOUT, START_LOADING, END_LOADING, CLEAR, FORGOT_PASSWORD, RESET_PASSWORD, ACTION} from '../constants/actionTypes';

const initialState = {
  authData: null,
  isLoading: false,
  forgot_password:[],
  reset_password:[],
  error: null

};
const authReducer = (state = initialState, action) => {
  if(action?.payload?.type === AUTH ){
    switch (action.type) {
      case START_LOADING:
        return {...state, isLoading:true}
      case END_LOADING:
        return {...state, isLoading:false}
      case AUTH:
        return { ...state, authData: action.payload.data, errors: null };
      case FORGOT_PASSWORD:
        return {...state, forgot_password: action.payload.data};
      case RESET_PASSWORD:
        return {...state, reset_password: action.payload.data};
      case ACTION:
        return {...state, authData:  action.payload.data};
      case LOGOUT:
        localStorage.clear();
        return { ...state, authData: null, errors: null };
      case CLEAR:
        localStorage.clear();
        return { ...state, authData: null, errors: null };
      default:
        return state;
    }
  }
  else{
      return state;
  }}
export default authReducer;