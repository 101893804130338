import { START_LOADING, END_LOADING, CREATE, UPDATE, DELETE, FETCH, FETCH_ALL, FETCH_BY_ID, FETCH_WITH_QUERY, REPORT} from '../constants/actionTypes';

const initialState = {
    report:[],
    isLoading: false,
};

const questionReportReducer = (state = initialState,action) =>{
    if(action?.payload?.type === REPORT ){
        switch (action.type){
            case START_LOADING:
                return {...state, isLoading:true}
            case END_LOADING:
                return {...state, isLoading:false}
            case FETCH:
                return {...state, report: action.payload};
            case FETCH_WITH_QUERY:
                return {...state, report: action.payload};
            case FETCH_BY_ID:
                return {...state, report: action.payload};
            case FETCH_ALL:
                return {...state, report: action.payload};        
            case CREATE:
                return { ...state, report: [...state.report, action.payload.data] };
            case UPDATE:
                return {...state, report: {data:state.report.data}};
            case DELETE:
                return {...state, report: {data:state.report.data.filter((report) => report._id !== action.payload.data._id),pagination:{...state.report.pagination, totalReports:state.report.pagination.totalReports-1}}};
            default:
                return state;
        }
    }
    else{
        return state;
    }}
export default questionReportReducer;