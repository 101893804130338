import axios from 'axios';
import { apiEndpoint } from '../constants/links';

const API = axios.create({
  withCredentials: true,
	baseURL: apiEndpoint,
  // timeout:2000,
});

//------------------------FETCH---------------------------//
export const fetch = (dir1,id) => API.get(`/${dir1}/${id}`);
export const fetchAll = (dir1) => API.get(`/${dir1}`);

//------------------------CREATE---------------------------//
export const create = (dir1,data) => API.post(`/${dir1}`, data);
export const createDoubleDir = (dir1,dir2,id,newData) => API.post(`/${dir1}/${dir2}/${id}`,newData);

//------------------------UPDATE---------------------------//
export const update = (dir1,id, data) => API.patch(`/${dir1}/update/${id}`, data);
export const updateDoubleDir = (dir1,dir2,id, data) => API.patch(`/${dir1}/${dir2}/${id}`, data);

//------------------------DELETE---------------------------//
export const remove = (dir1,id) => API.delete(`/${dir1}/${id}`);

//-----------------------DOUBLE DIRECTORY------------------//
export const fetchDoubleDir = (dir1,dir2,id) => API.get(`/${dir1}/${dir2}/${id}`);
export const fetchDoubleDirAll = (dir1,dir2) => API.get(`/${dir1}/${dir2}`);


//------------------------PATCH DOUBLE DIRECTORY---------------------------//
export const action = (path,id,data) => API.patch(`/${path}/${id?id:''}`,data);

export const fetchData = (path) => API.get(`${path}`);
export const fetchWithQuery = (path, config = {}) => API.get(`${path}`, config);
export const fetchFullInfo = (path) => API.get(`${path}`);
export const fetchFullInfoById = (path,id) => API.get(`${path}/${id}`);
export const fetchDataById = (path,id) => API.get(`${path}/${id}`);
export const createData = (path,data) => API.post(`${path}`, data);
export const updateData = (path,id, data) => API.patch(`${path}/${id}`, data);
export const deleteData = (path,id,data) => API.delete(`${path}${id?`/${id}`:''}`,data);

export const logout = () => API.post('/auth/logout');
export const signIn = (formData) => API.post('/auth/signIn', formData);
export const sendOtp = (formData) => API.post('/auth/send/otp', formData);
export const signUp = (formData) => API.post('/auth/signUp', formData);
export const forgotPassword = (email) => API.post('/auth/password/forgot', email);
export const sendMail = (formData) => API.post('/auth/contact-us', formData);
export const resetPassword = (password,resetToken) => API.post(`/auth/password/reset/${resetToken}`, password);

export const calculateResult = (examId,resultData) => API.post(`/result/calculate/${examId}`, resultData);
export const calculateAndSaveResult = (examId,resultData) => API.post(`/result/calculate-and-save/${examId}`, resultData);