import * as api from './../api/mainIndex';
import { clearError, setError } from './error.js';
import { AD_REDIRECT, USR_REDIRECT } from '../constants/path.js';
import { AUTH,START_LOADING,END_LOADING, FORGOT_PASSWORD, RESET_PASSWORD, CLEAR, SEND_MAIL, ACTION } from '../constants/actionTypes';

export const signIn = (formData, navigate) => async (dispatch) => {
  try {
    dispatch({type:START_LOADING,payload:{type:AUTH}})
    const { data } = await api.signIn(formData);
    dispatch({ type: AUTH, payload:{data, type:AUTH}});

    if(data?.status === 99){
      navigate(AD_REDIRECT);
    }
    else if(data?.status === 14){
      navigate(USR_REDIRECT);
    }

    dispatch({type:END_LOADING,payload:{type:AUTH}})
    dispatch(clearError())

  } catch (error) {
    if(error?.message === "Network Error")
      {
        dispatch(setError("Something went wrong!"))
        dispatch({type:END_LOADING,payload:{type:AUTH}})
      }
    else{
      dispatch(setError(error?.response?.data?.message))
      dispatch({type:END_LOADING,payload:{type:AUTH}})
    }
  }
};

export const signUp = (formData, handleNext, enqueueSnackbar) => async (dispatch) => {
  try {
    dispatch({type:START_LOADING,payload:{type:AUTH}})
    const { data } = await api.signUp(formData);
    dispatch({ type: AUTH, payload:{data, type:AUTH}});
    dispatch(clearError())
    dispatch({type:END_LOADING,payload:{type:AUTH}})
    if(data.message){
      enqueueSnackbar( data?.message, {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
    handleNext()

  } catch (error) {
    if(error?.message === "Network Error")
    {dispatch(setError("Something went wrong!"))
    dispatch({type:END_LOADING,payload:{type:AUTH}})
  }
  else{
    dispatch(setError(error?.response?.data?.message))
    dispatch({type:END_LOADING,payload:{type:AUTH}})
  }

  }
};

export const logout = (navigate) => async (dispatch) => {
  try {
    dispatch({type:START_LOADING,payload:{type:AUTH}});
    const { data } = await api.logout();
    dispatch({ type: AUTH,payload:{data,type:AUTH} });
    navigate('/auth/login');
    localStorage.clear();
    dispatch({type:END_LOADING,payload:{type:AUTH}})


  } catch (error) {
    dispatch(setError(error?.response?.data?.message))
    dispatch({type:END_LOADING,payload:{type:AUTH}})
  }
};

export const forgotPassword =(email) =>async(dispatch)=>{
  try {
      dispatch({type:START_LOADING,payload:{type:AUTH}})
      const {data} = await api.forgotPassword(email);
      dispatch({type:FORGOT_PASSWORD,payload:{data, type:AUTH}});
      dispatch({type:END_LOADING,payload:{type:AUTH}})

  } catch (error) {
      dispatch(setError(error?.response?.data?.message))
      dispatch({type:END_LOADING,payload:{type:AUTH}})
  }
}

export const sendMail =(formData,enqueueSnackbar) =>async(dispatch)=>{
  try {
      dispatch({type:START_LOADING,payload:{type:AUTH}})
      const {data} = await api.sendMail(formData);
      dispatch({type:ACTION,payload:{data, type:AUTH}});
      if(data?.message){
        enqueueSnackbar( data?.message, {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'left' },
        });
      }
      dispatch({type:END_LOADING,payload:{type:AUTH}})

  } catch (error) {
      dispatch(setError(error?.response?.data?.message));
      if(error?.response?.data?.message){
        enqueueSnackbar( error?.response?.data?.message, {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'left' },
        });
      }
      dispatch({type:END_LOADING,payload:{type:AUTH}})
  }
}

export const resetPassword =(password,resetToken, navigate ,enqueueSnackbar) =>async(dispatch)=>{
  try {
      dispatch({type:START_LOADING,payload:{type:AUTH}})
      const {data} = await api.resetPassword(password, resetToken);
      dispatch({type:RESET_PASSWORD,payload:{data, type:AUTH}});
      if(data?.message){
        enqueueSnackbar( data?.message, {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'left' },
        });
      }
      navigate('/auth/login')
      dispatch({type:END_LOADING,payload:{type:AUTH}})

  } catch (error) {
      dispatch(setError(error?.response?.data?.message))
      dispatch({type:END_LOADING,payload:{type:AUTH}})
  }
}

export const verifyEmail =(password,resetToken) =>async(dispatch)=>{
  try {
      dispatch({type:START_LOADING,payload:{type:AUTH}})
      const {data} = await api.resetPassword(password, resetToken);
      dispatch({type:RESET_PASSWORD,payload:{data, type:AUTH}});
      dispatch({type:END_LOADING,payload:{type:AUTH}})

  } catch (error) {
      dispatch(setError(error?.response?.data?.message))
      dispatch({type:END_LOADING,payload:{type:AUTH}})
  }
}

export const sendOtp = (formData, handleNext, enqueueSnackbar) => async (dispatch) => {
  try {
    dispatch({type:START_LOADING,payload:{type:AUTH}})
    const { data } = await api.sendOtp(formData);
    dispatch({ type: AUTH, payload:{data, type:AUTH}});
    dispatch(clearError())
    dispatch({type:END_LOADING,payload:{type:AUTH}})
    if(data.message){
      enqueueSnackbar( data?.message, {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
    handleNext()

  } catch (error) {
    if(error?.message === "Network Error")
    {dispatch(setError("Something went wrong!"))
    dispatch({type:END_LOADING,payload:{type:AUTH}})
  }
  else{
    dispatch(setError(error?.response?.data?.message))
    dispatch({type:END_LOADING,payload:{type:AUTH}})
  }

  }
};

export const clear = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR,payload:{type:AUTH} });

  } catch (error) {
    dispatch(setError(error?.response?.data?.message))
  }
};