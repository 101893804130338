import { START_LOADING,END_LOADING, FETCH, CREATE, USER,UPDATE, DELETE,PROFILE_PIC_UPDATE, CHANGE_PASSWORD , ACTION, FETCH_ALL,  SET_ERROR, CLEAR_ERROR, GET_ERROR, FETCH_BY_ID, FETCH_FULL_INFO, FETCH_FULL_INFO_BY_ID} from '../constants/actionTypes';

const initialState = {
    user:[],
    users:[],
    userInfo:null,
    updatedUser:[],
    updatePassword:[],
    isLoading: false,
    error: null
};

const userReducer = (state = initialState,action) =>{
    if(action?.payload?.type === USER ){
        switch (action.type){
            case GET_ERROR:
                return {
                ...state,
                error: null
                };
            case CLEAR_ERROR:
                return {
                ...state,
                error: null
                };
            case SET_ERROR:
                return {
                ...state,
                error: action.payload.error
                };

            case START_LOADING:
                return {...state, isLoading:true}
            case END_LOADING:
                return {...state, isLoading:false};
            case FETCH_FULL_INFO: 
                return {...state, userInfo: action.payload.data};
            case FETCH_FULL_INFO_BY_ID: 
                return {...state, userInfo: action.payload.data};
            case FETCH: 
                return {...state, user: action.payload.data};
            case FETCH_ALL: 
                return {...state, users: action.payload.data};
            case FETCH_BY_ID: 
            return {...state, user: action.payload.data};
            case CHANGE_PASSWORD:
                return {...state, updatePassword: action.payload.data};        
            case CREATE:
                return { ...state, user: [...state.user, action.payload] };
            case PROFILE_PIC_UPDATE:
                return {...state, updatedUser: action.payload.data};
            case UPDATE:
                return {...state, user: action.payload.data};
            case DELETE:
                return {...state, user: state.user.map((user) => user._id !== action.payload) };
            case ACTION:
                return {...state, user:  action.payload.data};
            default:
                return state;
            }
    }
    else{
        return state;
    }
};
export default userReducer;