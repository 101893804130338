import { START_LOADING, END_LOADING, FETCH_ALL, FETCH, CREATE, UPDATE, DELETE, FETCH_BY_ID, AI, SET_ERROR, CLEAR_ERROR} from '../constants/actionTypes';

const initialState = {
    aiAnswer:null,
    isLoading: false,
    error:null,
};

const aiReducer = (state = initialState,action) =>{
    if(action?.payload?.type === AI ){
    switch (action.type){
        case START_LOADING:
            return {...state, isLoading:true}
        case END_LOADING:
            return {...state, isLoading:false}
        case FETCH:
        case FETCH_BY_ID:
        case FETCH_ALL:
            return { ...state, aiAnswer: action.payload.data };  
        case CREATE:
            return { ...state, aiAnswer: [...state.aiAnswer, action.payload.data] };
        case UPDATE:
            return {...state, aiAnswer: action.payload.data};
        case DELETE:
            return {...state, aiAnswer: state.aiAnswer.map((aiAnswer) => aiAnswer._id !== action.payload) };
        case SET_ERROR:
            return { ...state, error: action.payload.errorMsg };   
        case CLEAR_ERROR:
            return { ...state, error: null };   
        default:
            return state;

    }
}
else{
    return state;
}}

export default aiReducer;