import { combineReducers } from 'redux';

import aiReducer from './aiReducer';
import userReducer from './userReducer';
import examReducer from './examReducer';
import authReducer from './authReducer';
import themeReducer from './themeReducer';
import errorReducer from './errorReducer';
import resultReducer from './resultReducer';
import dataReducer from './shareDataReducer';
import successReducer from './successReducer';
import categoryReducer from './categoryReducer';
import questionReducer from './questionReducer';
import subscriptionReducer from './subscriptionReducer';
import questionReportReducer from './questionReportReducer';

export const reducers = combineReducers({error: errorReducer,data:dataReducer,report:questionReportReducer,ai:aiReducer, success: successReducer ,theme:themeReducer,subscription:subscriptionReducer,question:questionReducer,auth:authReducer,user:userReducer,exam:examReducer,result:resultReducer,category:categoryReducer});