import { START_LOADING, END_LOADING, CREATE, UPDATE, DELETE, QUESTION, FETCH, FETCH_ALL, FETCH_BY_ID, FETCH_WITH_QUERY} from '../constants/actionTypes';

const initialState = {
    question:[],
    isLoading: false,
};

const questionReducer = (state = initialState,action) =>{
    if(action?.payload?.type === QUESTION ){
        switch (action.type){
            case START_LOADING:
                return {...state, isLoading:true}
            case END_LOADING:
                return {...state, isLoading:false}
            case FETCH:
                return {...state, question: action.payload};
            case FETCH_WITH_QUERY:
                return {...state, question: action.payload};
            case FETCH_BY_ID:
                return {...state, question: action.payload};
            case FETCH_ALL:
                return {...state, question: action.payload};        
            case CREATE:
                return { ...state, question: [...state.question, action.payload.data] };
            case UPDATE:
                return {...state, question: {data:state.question.data.map((question)=> (question._id === action.payload.data._id?action.payload.data:question))}};
            case DELETE:
                return {...state, question: {data:state.question.data.filter(
                    (question) => Array.isArray(action.payload.data._id) // If multiple IDs
                        ? !action.payload.data._id.includes(question._id)
                        : question._id !== action.payload.data._id // If single ID
                    ), pagination:{...state.question.pagination, totalQuestions:Array.isArray(action.payload.data._id)? state.question.pagination.totalQuestions-action.payload.data._id.length : state.question.pagination.totalQuestions-1}}};
            default:
                return state;
        }
    }
    else{
        return state;
    }}
export default questionReducer;