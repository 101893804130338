export const PROFILE_UPDATE = 'user/update/profile';
export const PROFILE_PIC_UPDATE = 'user/update/profile/picture';
export const UPDATE_PROFILE_PIC = 'user/profile/update/picture';
export const SUB_EXTEND = 'user/subscription/extend';
export const SUB_EXPIRE = 'user/subscription/expire';
export const SUB_REJECT = 'user/subscription/reject';
export const SUB_NEW = 'user/subscription/new';
export const UNSUBSCRIBE = 'user/unsubscribe';
export const SUB_CANCEL = 'user/cancel';
export const SUB_CHANGE = 'user/subscription/change';
export const SUB_APPROVE = 'user/subscription/approve';
export const SUB_APPROVE_UPDATE ='user/subscription/updated/approve';
export const SUB_UPDATE ='user/subscription/update';
export const USER_INFO = 'user/info';
export const USER_ACCOUNT_INFO = 'user/account/profile';
export const USER_LIST = 'user/list';
export const USER_SEARCH = 'user/search';
export const USER_CREATE = 'user/create/new';
export const FREE_EXAM = 'exam/free';
export const PAID_EXAM = 'exam/paid';
export const EXAM_RESULT = 'exam/examResult';
export const FREE_EXAM_WITH_CATEGORY = 'exam/free/category';
export const PAID_EXAM_WITH_CATEGORY = 'exam/paid/category';
export const ADMIN_EXAM = 'exam/admin';
export const EXAM_LIST = 'exam/admin/list';
export const EXAM_CREATE = 'exam/admin/create';
export const EXAM_SORT = 'exam/admin/sort';
export const EXAM_LIST_FREE = 'exam/admin/list/free';
export const EXAM_LIST_PAID = 'exam/admin/list/paid';
export const EXAM_UPDATE = 'exam/admin/update';
export const EXAM_DELETE = 'exam/admin/delete';
export const EXAM_TOGGLE_STATUS = 'exam/admin/change/status';

export const FETCH_STATUS = 'report/status';
export const AD_REDIRECT = '/admin/dashboard/main';
export const USR_REDIRECT = '/user/dashboard';

export const LOGIN ='auth/signIn';
export const LOGOUT ='auth/logout';
export const SIGNUP ='auth/signUp';
export const PS_RESET ='auth/password/reset';
export const VALIDATE_EMAIL ='auth/send/otp';
export const PS_FORGOT ='auth/password/forgot';
export const PS_CHANGE = 'auth/password/change';
export const AD_PS_CHANGE = 'auth/admin/password/change';

export const CREATE_CAT ='category/admin/create';
export const LIST_CAT ='category/admin/list/all';
export const UPDATE_CAT ='category/admin/update';
export const DELETE_CAT ='category/admin/delete';
export const CAT_TOGGLE_STATUS = 'category/admin/change/status';

export const DELETE_QUE = 'question/admin/delete';
export const UPDATE_QUE = 'question/admin/update';

export const DELETE_REPORT = 'report/admin/delete';

export const AI_ANSWER = 'ai/answer'
export const AI_ANSWER_GUEST = 'ai/answer/guest'




